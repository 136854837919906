<template>
  <div class="admin-create-api-client">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">Create API Client</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Basic Details -->
    <div class="form" v-if="!isLoading">
      <!-- Row 1 -->
      <a-row :gutter="30">
        <a-col :span="12">
          <a-form-item label="Name">
            <a-input
              size="large"
              v-model="apiClientLocal.displayName"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Headline">
            <a-input size="large" v-model="apiClientLocal.headline"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <!-- / Row 1 -->

      <!-- Row 2 -->
      <a-row :gutter="30">
        <a-col :span="12">
          <a-form-item label="User Name">
            <a-input
              size="large"
              v-model="apiClientLocal.grantPresenceName"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Event Callback URL">
            <a-input
              size="large"
              v-model="apiClientLocal.callbackUrl"
            ></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <!-- / Row 2 -->
    </div>
    <!-- / Basic Details -->

    <!-- Images -->
    <div class="form" v-if="!isLoading">
      <a-row :gutter="30">
        <a-col :span="12">
          <a-form-item label="Group Icon">
            <a-upload
              :multiple="false"
              :file-list="avatarFileList"
              :before-upload="beforeAvatarUpload"
              :remove="handleAvatarRemove"
            >
              <a-button>
                <a-icon type="upload"></a-icon>
                Click to Upload
              </a-button>
            </a-upload>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Logo">
            <a-upload
              :multiple="false"
              :file-list="logoFileList"
              :before-upload="beforeLogoUpload"
              :remove="handleLogoRemove"
            >
              <a-button>
                <a-icon type="upload"></a-icon>
                Click to Upload
              </a-button>
            </a-upload>
          </a-form-item>
        </a-col>
      </a-row>
       <p>
        Please upload a 200px x 200px image for the group icon.
        Please upload a 240px x 80px image for the logo.
      </p>
    </div>
    <!-- / Images -->

    <!-- Permissions -->
    <div
      class="form"
      v-if="!isLoading"
      :class="{ last: !isCreateViaEmailPermissionSelected }"
    >
      <a-form-item
        style="margin-bottom: 0"
        label="Requested Permissions"
      ></a-form-item>
      <permission-selector
        :selected-permission-values="selectedPermissionValues"
        @toggle-permission="togglePermissionSelected"
      ></permission-selector>
    </div>
    <!-- / Permissions -->

    <!-- Email Parser -->
    <div class="form" v-if="!isLoading && isCreateViaEmailPermissionSelected">
      <a-form-item label="Email Parser">
        <a-input
          size="large"
          v-model="apiClientLocal.requestedParser"
        ></a-input>
      </a-form-item>
    </div>
    <!-- / Email Parser -->

    <a-button
      @click.prevent="save"
      class="btn-rounded save-button"
      size="large"
      type="primary"
      >Save</a-button
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import apiClients from "../../../../api/api-clients";
// import apiClientHelpers from "../../../../helpers/api-clients";
import PermissionSelector from "../../../../components/Admin/PermissionSelector.vue";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
const _ = require("lodash");
export default {
  components: { PermissionSelector, LoadingScreen },

  data() {
    return {
      apiClientLocal: {
        displayName: "",
        headline: "",
        grantPresenceName: "",
        callbackUrl: "",
        requestedParser: "",
      },

      avatarFileList: [],
      logoFileList: [],

      selectedPermissionValues: [],

      isSaving: false,
    };
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    if (!this.canEditApiClient) {
      this.$message.info("You don't have permission to create an API client");
      this.$router.push("/admin/integration/api-clients");
    }
  },

  methods: {
    ...mapActions("adminApiClients", {
      pushToApiClientsAddedLocally: "pushToApiClientsAddedLocally",
    }),

    goBack() {
      this.$router.push("/admin/integration/api-clients");
    },

    handleAvatarRemove(file) {
      const index = this.avatarFileList.indexOf(file);
      const newFileList = this.avatarFileList.slice();
      newFileList.splice(index, 1);
      this.avatarFileList = newFileList;
    },

    handleLogoRemove(file) {
      const index = this.logoFileList.indexOf(file);
      const newFileList = this.logoFileList.slice();
      newFileList.splice(index, 1);
      this.logoFileList = newFileList;
    },

    beforeAvatarUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("Please upload a JPG or a PNG");
      }
      if (isJpgOrPng) {
        this.avatarFileList = [file];
      }
      return false;
    },

    beforeLogoUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("Please upload a JPG or a PNG");
      }
      if (isJpgOrPng) {
        this.logoFileList = [file];
      }
      return false;
    },

    togglePermissionSelected(val) {
      this.selectedPermissionValues = _.xor(this.selectedPermissionValues, [
        val,
      ]);
    },

    async addApiClientThumb(tenantId, apiClientId) {
      let vm = this;
      return apiClients.addApiClientThumb(
        tenantId,
        apiClientId,
        vm.avatarFileList[0]
      );
    },

    async addApiClientLogo(tenantId, apiClientId) {
      let vm = this;
      return apiClients.addApiClientLogo(
        tenantId,
        apiClientId,
        vm.logoFileList[0]
      );
    },

    async save() {
      let vm = this;

      vm.isSaving = true;

      let params = {
        ...this.apiClientLocal,
        requestedPermissions: _.sum(this.selectedPermissionValues),
        ownerId: this.selectedOrganisation.id,
      };

      if (!this.createViaEmailPermission && params["requestedParser"]) {
        delete params["requestedParser"];
      }

      apiClients
        .addApiClient(this.tenantId, params)
        .then(async (r) => {
          let apiClientId = r.data.id;

          if (vm.avatarFileList.length) {
            try {
              await vm.addApiClientThumb(this.tenantId, apiClientId);
            } catch (e) {
              console.log(e);
            }
          }

          if (vm.logoFileList.length) {
            try {
              await vm.addApiClientLogo(this.tenantId, apiClientId);
            } catch (e) {
              console.log(e);
            }
          }

          vm.pushToApiClientsAddedLocally(r.data);

          vm.$router.push("/admin/integration/api-clients");
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
      myPresenceId: "myPresenceId",
      isMyPresenceHidden: "isMyPresenceHidden",
    }),

    ...mapGetters("permissions", {
      canEditApiClient: "canEditApiClient"
    }),

    createViaEmailPermission() {
      return { description: "Create new incidents via email", value: 8192 };
    },

    isCreateViaEmailPermissionSelected() {
      if (!this.createViaEmailPermission) {
        return false;
      }
      return _.includes(
        this.selectedPermissionValues,
        this.createViaEmailPermission.value
      );
    },
  },
};
</script>

<style scoped lang="scss">
.admin-create-api-client {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
    margin-bottom: 20px;

    &.last {
      margin-bottom: 0;
    }
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }
}
</style>