<template>
  <div class="permission-selector">
    <a-input-search
      placeholder="Search..."
      style="width: 230px; margin-bottom: 20px"
      v-model="searchQuery"
    />

    <a-row :gutter="10" type="flex">
      <a-col :span="colSpan" v-for="(permission, i) in permissionsToShow" :key="i">
        <div class="permission-selector">
              <a-checkbox :disabled="disabled == true"
            :checked="isPermissionSelected(permission)"
            @change="$emit('toggle-permission', permission.value)"
          >
           {{ permission.description }}
          </a-checkbox>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
const _ = require("lodash");
export default {
  data() {
    return {
        windowWidth: window.innerWidth,
        searchQuery: "",
    };
  },
  props: ['selectedPermissionValues', 'disabled'],
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  computed: {
    ...mapGetters('adminApiClients', {
      isLoadingApiGrantPermissionOptions: 'isLoadingApiGrantPermissionOptions',
      apiGrantPermissionOptions: 'apiGrantPermissionOptions'
    }),

    allPermissions() {
      return this.apiGrantPermissionOptions;
    },

    colSpan() {
      if (this.windowWidth > 1600) {
        return 12;
      }
      return 24;
    },

    allowedPermissions() {
        return _.filter(this.allPermissions, p => {
            return !p.notOnApiClients
        });
    },

    permissionsToShow() {
        let permissions = this.allowedPermissions;

        if (this.searchQuery && this.searchQuery.trim().length) {
        return _.filter(permissions, (permission) => {
            return permission.description
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
        }

        return permissions;
    }
  },
  methods: {
    isPermissionSelected(permission) {
        return this.selectedPermissionValues.includes(permission.value);
    }
  },
};
</script>

<style scoped lang="scss">
.permission-selector {
    margin-bottom: 15px;
}
</style>